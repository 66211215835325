@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../node_modules/leaflet/dist/leaflet.css';
@import './pages/map.scss';

a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

html {
  font-size: 14px;
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 60px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
  height: 60px;
  line-height: 60px;
}

.hidden {
  display: none !important;
}
