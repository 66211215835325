﻿.map-page {
  .map {
    height: 580px;
  }

  .buttons-row {
    margin-bottom: 17px;
  }

  .crosshair-cursor-enabled {
    cursor: alias;
  }

  #drop-area {
    border: 2px dashed #ccc;
    border-radius: 20px;
    font-family: sans-serif;
    padding: 20px;
  }

  #drop-area.highlight {
    border-color: purple;
  }

  p {
    margin-top: 0;
  }

  #gallery {
    margin-top: 10px;
  }

  .button {
    display: inline-block;
    padding: 10px;
    background: #ccc;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  .button:hover {
    background: #ddd;
  }

  .img-preview {
    width: 100px;
    height: 100px;

    img {
      max-width: 100px;
      max-height: 100px;
    }
  }

  #gallery img {
    width: 150px;
    margin-bottom: 10px;
    margin-right: 10px;
    vertical-align: middle;
  }

  .route-popup {
    width: 160px;

    .row {
      margin-top: 7px;
      margin-bottom: 7px;
    }
  }
}

#fileElem {
  display: none;
}
